<template>
  <div class="mx-auto">
    <TitleWrapper
      :filter-option="false"
      title="LESSON_PLANNER_SETTINGS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div class="minHieght80 flex-col">
      <div v-if="!loading">
        <LessonCheckbox
          v-for="(val, indexVal) in settingsArray"
          :key="indexVal"
          class="mt-6"
          :title="indexVal"
          parent-module="lesson-planner"
          :modules-array="val"
          @updateSettings="getSettings"
        />
      </div>
      <Loader v-else class="mt-28" :content="true" />
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import LessonCheckbox from '@src/components/Settings/attandance-settings/PermissionSettingsCheckbox.vue'
import { removeExtraRolePermissions } from '@utils/permissions'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import Loader from '@components/BaseComponent/Loader.vue'
/* 3rd party Component */
import { mapActions, mapState } from 'vuex'
/**
 * @notes IMPORTANT NOTES
 * index ACTION FOR VIEW
 * create ACTION FOR Create
 * update ACTION FOR Edit
 * deactivate ACTION FOR delete
 * */

export default {
  components: {
    TitleWrapper,
    LessonCheckbox,
    Loader,
  },
  data: function () {
    return {
      settingsArray: [],
      loading: false,
      excludeRoleSettings: [
        TENANT_ROLES.SECTION_ADMIN,
        TENANT_ROLES.SECTION_MODERATOR,
        TENANT_ROLES.CAMPUS_MODERATOR,
      ],
    }
  },
  page: {
    title: 'System Settings | Lesson Planner Settings',
  },
  computed: {
    ...mapState('layout', [
      'currentCampusScope',
      'currentSectionScope',
      'activeRole',
      'currentClassScope',
    ]),
  },
  watch: {
    currentCampusScope: {
      handler: function () {
        this.getSettings()
      },
      deep: true,
    },
    currentSectionScope: {
      handler: function (val) {
        this.getSettings()
      },
      deep: true,
    },
    activeRole: {
      handler: function () {
        this.getSettings()
      },
      deep: true,
    },
    currentClassScope: {
      handler() {
        this.getSettings()
      },
    },
  },

  mounted() {
    this.getSettings()
  },

  methods: {
    async getSettings() {
      this.loading = true
      const [res, err] = await this.getLessonPlannerPermissionSettings()
      removeExtraRolePermissions(this.excludeRoleSettings, res.data)
      this.settingsArray = res.data
      this.loading = false
    },
    ...mapActions('settings', ['getLessonPlannerPermissionSettings']),
  },
}
</script>
